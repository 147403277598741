import React, { useMemo, useEffect } from "react"
import { graphql } from "gatsby"
import { Location } from "@reach/router"
import ProjectPreviewSmall from "@/components/projectPreview/small"

const ProjectsByLocation = ({ location, projects }) => {
  const urlParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  return (
    <>
      {projects
        .filter((p) =>
          urlParams.get("category")
            ? p.category.some((c) => c.slug === urlParams.get("category"))
            : true
        )
        .map((p) => (
          <div
            key={`${location.search}-${p.slug}`}
            className={"w-full tablet:w-1/2 px-3 pt-17 pb-3"}
          >
            <ProjectPreviewSmall
              {...p}
              coverAsABackground={p.smallCoverAsABackground}
            />
          </div>
        ))}
    </>
  )
}

export default function ProjectsPage({ data, transitionStatus }) {
  const projects =
    data?.allContentfulProjectsList?.edges?.[0]?.node?.projects ?? []

  return (
    <Location>
      {({ location }) => (
        <div
          className={`w-full overflow-hidden px-3 desktop:px-7 pt-12 g-transition-status-${transitionStatus}`}
        >
          <div className={"flex flex-wrap pb-14"} style={{ margin: "0 -15px" }}>
            <ProjectsByLocation location={location} projects={projects} />
          </div>
        </div>
      )}
    </Location>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulProjectsList(
      filter: {
        slug: { eq: "projects-all" }
        projects: { elemMatch: { node_locale: { eq: "en-US" } } }
      }
    ) {
      edges {
        node {
          projects {
            title {
              title
            }
            slug
            shortDescription {
              shortDescription
            }
            shortDescriptionColor
            cover {
              fluid(maxWidth: 1000, quality: 70, toFormat: JPG) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            smallCoverAsABackground
            coverColor
            category {
              slug
            }
          }
        }
      }
    }
  }
`
